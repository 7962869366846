import React from "react"
import styled from "styled-components"

import Link from "@atoms/link"
import Logo from "@atoms/logo"
import Button from "@atoms/button"
import { media } from "@styles/breakpoints"

const NavAffiliates = () => {
  return (
    <Nav>
      <Content>
        <Link to="/">
          <Logo />
        </Link>
        <Links>
          <NavLink>
            <Login href="https://affiliates.moonclerk.com/login" rel="nofollow">Affiliate Login</Login>
          </NavLink>
          <NavLink>
            <AffiliateButton href="https://affiliates.moonclerk.com/signup" rel="nofollow">Become an Affiliate</AffiliateButton>
          </NavLink>
        </Links>
      </Content>
    </Nav>
  )
}

export default NavAffiliates

const Nav = styled.div`
  align-items: center;
  border-bottom: var(--borderPrimary);
  background-color: white;
  display: none;
  left: 0;
  padding: var(--spacingSection);
  position: fixed;
  justify-content: center;
  top: 0;
  width: 100vw;
  height: 88px;
  z-index: var(--zTop);

  ${media.sm`
    display: flex;
  `}
`

const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  max-width: var(--container--xl);
  width: 100%;
`

const Links = styled.ul`
  align-items: center;
  display: flex;
`

const NavLink = styled.li`
  padding-left: 1rem;
`

const Login = styled.a`
  color: var(--gray--60);
  text-decoration: none;
  display: none;

  ${media.sm`
    display: inline-block;
  `}
`

const AffiliateButton = styled(Button)`
  display: none;

  ${media.sm`
    display: inline-block;
  `}
`
