import React, { useEffect, useRef } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import styled from "styled-components"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { HelmetDatoCms } from "gatsby-source-datocms"

import NavAffiliates from "@molecules/navAffiliates"
import { Section, Container, Flex, Column, Block, Content } from "@atoms/layout"
import { Heading, Paragraph } from "@atoms/typography"
import Button from "@atoms/button"
import { media } from "@styles/breakpoints"
import Layout from "@utils/layout"

const Affiliates = ({ data }) => {
  const signup = useRef()
  const signupVisual = useRef()
  const share = useRef()
  const shareVisual = useRef()
  const earn = useRef()
  const earnVisual = useRef()

  useEffect(() => {
    if (typeof window !== `undefined`) {
      gsap.registerPlugin(ScrollTrigger)
      gsap.core.globals("ScrollTrigger", ScrollTrigger)
    }

    ScrollTrigger.matchMedia({
      "(max-width: 480px)": function () {
        let signupTl = gsap.timeline({
          scrollTrigger: {
            trigger: signupVisual.current,
            start: "top center",
            end: "center center",
            scrub: 1,
          },
        })
        signupTl
          .addLabel("start")
          .from(".visual--signup--0", { scale: 0 })
          .from(".visual--signup--1", { scale: 0 })
          .from(".visual--signup--2", { scale: 0 })
          .from(".visual--signup--3", { opacity: 0, yPercent: 10, duration: 4 })
          .addLabel("end")

        let shareTl = gsap.timeline({
          scrollTrigger: {
            trigger: shareVisual.current,
            start: "top center",
            end: "center center",
            scrub: 1,
          },
        })

        shareTl
          .addLabel("start")
          .from(".visual--share--3", { opacity: 0, rotate: 45, duration: 2 })
          .from(".visual--share--0", { scale: 0 })
          .from(".visual--share--1", { scale: 0 })
          .from(".visual--share--2", { scale: 0 })
          .addLabel("end")

        let earnTl = gsap.timeline({
          scrollTrigger: {
            trigger: earnVisual.current,
            start: "top center",
            end: "center center",
            scrub: 1,
          },
        })

        earnTl
          .addLabel("start")
          .from(".visual--earn--0", { opacity: 0, yPercent: 10, duration: 2 })
          .from(".visual--earn--1", { opacity: 0 })
          .from(".visual--earn--2", { opacity: 0, yPercent: 5 })
          .from(".visual--earn--3", { opacity: 0, yPercent: 5 })
          .from(".visual--earn--4", { opacity: 0, yPercent: 5 })
          .addLabel("end")
      },
      "(min-width: 480px)": function () {
        let signupTl = gsap.timeline({
          scrollTrigger: {
            trigger: signup.current,
            start: "top center",
            end: "center center",
            scrub: 1,
          },
        })
        signupTl
          .addLabel("start")
          .from(".visual--signup--0", { scale: 0 })
          .from(".visual--signup--1", { scale: 0 })
          .from(".visual--signup--2", { scale: 0 })
          .from(".visual--signup--3", { opacity: 0, yPercent: 10, duration: 4 })
          .addLabel("end")

        let shareTl = gsap.timeline({
          scrollTrigger: {
            trigger: share.current,
            start: "top center",
            end: "center center",
            scrub: 1,
          },
        })

        shareTl
          .addLabel("start")
          .from(".visual--share--3", { opacity: 0, rotate: 45, duration: 2 })
          .from(".visual--share--0", { scale: 0 })
          .from(".visual--share--1", { scale: 0 })
          .from(".visual--share--2", { scale: 0 })
          .addLabel("end")

        let earnTl = gsap.timeline({
          scrollTrigger: {
            trigger: earn.current,
            start: "top center",
            end: "center center",
            scrub: 1,
          },
        })

        earnTl
          .addLabel("start")
          .from(".visual--earn--0", { opacity: 0, yPercent: 10, duration: 2 })
          .from(".visual--earn--1", { opacity: 0 })
          .from(".visual--earn--2", { opacity: 0, yPercent: 5 })
          .from(".visual--earn--3", { opacity: 0, yPercent: 5 })
          .from(".visual--earn--4", { opacity: 0, yPercent: 5 })
          .addLabel("end")
      },
    })
  })

  return (
    <Layout>
      <HelmetDatoCms seo={data.datoCmsAffiliate.seoMetaTags} />
      <div data-datocms-noindex>
        <NavAffiliates />
        <Section xlTop xl>
          <Intro>
            <Heading html="h1" level="h1" center>
              {data.datoCmsAffiliate.heading}
            </Heading>
            <Paragraph level="md" center>
              {data.datoCmsAffiliate.intro}
            </Paragraph>
          </Intro>
          <Button href="https://affiliates.moonclerk.com/signup" rel="nofollow" primary solid>
            Become an Affiliate
          </Button>
          <ButtonMobile
            href="https://affiliates.moonclerk.com/signup"
            rel="nofollow"
            secondary
            border
          >
            Affiliate Login
          </ButtonMobile>
        </Section>
        <Section xl full>
          <Container containerFull>
            {data.datoCmsAffiliate.content.map((section, index) =>
              section.__typename === "DatoCmsSignUp" ? (
                <Flex key={index} ref={signup} alignCenter>
                  <Visual half ref={signupVisual}>
                    {section.images.map((visual, index) => (
                      <div
                        className={`visual--signup visual--signup--${index}`}
                        key={index}
                      >
                        <GatsbyImage
                          image={visual.gatsbyImageData}
                          alt={visual.alt}
                          title={visual.title}
                        />
                      </div>
                    ))}
                  </Visual>
                  <Column half>
                    <BlockContent>
                      <Heading html="h2" level="h2">
                        {section.title}
                      </Heading>
                      <Content
                        dangerouslySetInnerHTML={{
                          __html: section.content,
                        }}
                      ></Content>
                    </BlockContent>
                  </Column>
                </Flex>
              ) : section.__typename === "DatoCmsShare" ? (
                <Flex key={index} ref={share} alignCenter>
                  <Visual half ref={shareVisual}>
                    {section.images.map((visual, index) => (
                      <div
                        className={`visual--share visual--share--${index}`}
                        key={index}
                      >
                        <GatsbyImage
                          image={visual.gatsbyImageData}
                          alt={visual.alt}
                          title={visual.title}
                        />
                      </div>
                    ))}
                  </Visual>
                  <Column half>
                    <BlockContent>
                      <Heading html="h2" level="h2">
                        {section.title}
                      </Heading>
                      <Content
                        dangerouslySetInnerHTML={{
                          __html: section.content,
                        }}
                      ></Content>
                    </BlockContent>
                  </Column>
                </Flex>
              ) : section.__typename === "DatoCmsEarn" ? (
                <Flex key={index} ref={earn} alignCenter>
                  <Visual half ref={earnVisual}>
                    {section.images.map((visual, index) => (
                      <div
                        className={`visual--earn visual--earn--${index}`}
                        key={index}
                      >
                        <GatsbyImage
                          image={visual.gatsbyImageData}
                          alt={visual.alt}
                          title={visual.title}
                        />
                      </div>
                    ))}
                  </Visual>
                  <Column half>
                    <BlockContent>
                      <Heading html="h2" level="h2">
                        {section.title}
                      </Heading>
                      <Content
                        dangerouslySetInnerHTML={{
                          __html: section.content,
                        }}
                      ></Content>
                    </BlockContent>
                  </Column>
                </Flex>
              ) : null
            )}
          </Container>
        </Section>
        <Section>
          <Container containerSmall xl>
            <Flex column alignCenter>
              <Heading html="h3" level="h3" center>
                Read the MoonClerk Affiliate Program help docs for more details
                and the fine print.
              </Heading>
              <Button
                href="http://help.moonclerk.com/en/collections/1609083-moonclerk-affiliate-program"
                secondary
                rel="nofollow"
                border
                sm
              >
                Help Documentation
              </Button>
            </Flex>
          </Container>
        </Section>
        <Section aqua xl xlTop>
          <Container>
            <Flex column alignCenter>
              <Heading level="h2" html="h2" center>
                Sign up and start earning today.
              </Heading>
              <Button
                href="https://affiliates.moonclerk.com/signup"
                primary
                rel="nofollow"
                solid
              >
                Become an Affiliate
              </Button>
            </Flex>
          </Container>
        </Section>
      </div>
    </Layout>
  )
}

export default Affiliates

const Intro = styled.div`
  margin-top: var(--spacing--sm);
  margin-bottom: 1rem;

  ${media.sm`
    margin-top: 0;
    margin-bottom: 0;
  `}
`

const ButtonMobile = styled(Button)`
  display: inline-block;
  margin-top: 0.5rem;

  ${media.sm`
    display: none;
`}
`

const Visual = styled(Column)`
  background-color: var(--gray--00);
  height: 480px;
  overflow: hidden;

  ${media.sm`
    height: 100vh;
  `}

  .visual--signup,
  .visual--share,
  .visual--earn {
    bottom: auto;
    left: auto;
    margin: auto;
    position: absolute;
    right: auto;
    top: auto;
    width: 100%;
  }

  .visual--share--3 {
    transform-origin: left bottom;
    position: relative;

    &::after {
      background: linear-gradient(
        180.17deg,
        rgba(248, 248, 251, 0) 0.77%,
        #f8f8fb 94.61%
      );
      bottom: 0;
      left: 0;
      height: 160px;
      position: absolute;
      width: 100%;
      z-index: 10;
    }
  }
`

const BlockContent = styled(Block)`
  padding: var(--spacing--xxs) var(--spacing--sm) var(--spacing--md);
  max-width: 720px;

  ${media.sm`
    padding: var(--spacing--sm);
  `}

  ${media.md`
    padding: var(--spacing--lg) var(--spacing--md);
    margin-right: var(--spacing--md);
  `}
`

export const fetchAffiliates = graphql`
  query fetchAffiliates {
    datoCmsAffiliate {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      heading
      intro
      content {
        ... on DatoCmsSignUp {
          __typename
          id
          content
          title
          images {
            alt
            title
            gatsbyImageData(
              width: 960
              layout: CONSTRAINED
              placeholder: BLURRED
              forceBlurhash: false
              imgixParams: { fm: "webp, png", auto: "compress", q: 90 }
            )
          }
        }
        ... on DatoCmsShare {
          __typename
          id
          content
          title
          images {
            alt
            title
            gatsbyImageData(
              width: 960
              layout: CONSTRAINED
              placeholder: BLURRED
              forceBlurhash: false
              imgixParams: { fm: "webp, png", auto: "compress", q: 90 }
            )
          }
        }
        ... on DatoCmsEarn {
          __typename
          id
          content
          title
          images {
            alt
            title
            gatsbyImageData(
              width: 960
              layout: CONSTRAINED
              placeholder: BLURRED
              forceBlurhash: false
              imgixParams: { fm: "webp, png", auto: "compress", q: 90 }
            )
          }
        }
      }
    }
  }
`
